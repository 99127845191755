import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { enumToArray } from '../CommonJs/utilTs';

export interface FormGroupProps
{
    label?:string;
    showLabel?:boolean;
    value?:any;
    optionsEnum?:any;
    options?:any[];
    optionLabel?:string;
    onChange?:(value:string)=>void;
    onOptionChange?:(value:number)=>void;
    type?:string;
    children?:any;
    propName?:string;
    setter?:any;
    readOnly?:boolean;
    autoFocus?:boolean;
    onBlur?:()=>void;
}

export default function FormGroup({
        label,
        showLabel=true,
        children,
        type="text",
        value,
        onChange,
        propName,
        setter,
        options,
        optionsEnum,
        optionLabel,
        onOptionChange,
        readOnly=false,
        autoFocus,
        onBlur
    }:FormGroupProps){

    if(readOnly){
        onChange=undefined;
        onOptionChange=undefined;
        setter=undefined;
    }

    if(optionsEnum){
        options=enumToArray(optionsEnum);
        optionLabel='name';
    }

    const cb=useCallback((e)=>{
        if(onOptionChange){
            onOptionChange(Number(e.target.value));
            return;
        }
        if(onChange){
            onChange(e.target.value);
            return;
        }

        if(propName && setter && typeof(value)==='object'){
            const v={...value};
            v[propName]=type==='number'?Number(e.target.value):e.target.value;
            setter(v);
        }
        
    },[onChange,propName,value,setter,type,onOptionChange]);

    const v=(propName&&value)?value[propName]:value;

    const onFocus=useCallback((e)=>{
        if(readOnly){
            try{
                e.target.blur();
            }catch{}
        }
    },[readOnly]);

    return (
        <Form.Group>
            {(showLabel&&label)&&<Form.Label>{label}</Form.Label>}
            {options?
                <select value={value} onChange={cb} className="form-control" onFocus={onFocus} onBlur={onBlur}>
                    {options.map((o:any,i:number)=>{
                        
                        const value=(optionsEnum&&options)?options[i].value:i;

                        return (
                            <option key={i} value={value}>{(o&&optionLabel?o[optionLabel]:o)}</option>
                        )
                        })}
                </select>
                :
                <Form.Control autoFocus={autoFocus} type={type} onChange={cb} value={!v?'':v}
                    placeholder={label} readOnly={readOnly} onFocus={onFocus} onBlur={onBlur} />
            }
            {children}
        </Form.Group>
    )
}