import React from 'react';
import { useAppAsync } from '../../lib/context';
import Loader from '../Loader';
import Empty from '../Empty';
import { ListGroup } from 'react-bootstrap';
import { Link } from '../../CommonJs/Nav';
import { Team } from '../../lib/types';

export default function Teams()
{

    const items=useAppAsync(null,({http})=>http.getAsync<Team[]>('Team'));

    if(!items){
        return <Loader/>
    }

    if(!items.length){
        return (
            <Empty title="No teams found" button="Create a new Team" to="/team/create">
                Your not part of any teams yet.
            </Empty>
        );
    }

    return (<>
        <h3>Teams</h3>

        <ListGroup>
            {items.map((i,index)=>(
                <ListGroup.Item key={index}><Link to={'/team/'+i.Id}>{i.Name}</Link></ListGroup.Item>
            ))}
            
        </ListGroup>

        <Link btnType="primary" to="/team/create">Create New</Link>
        
    </>)
}