import React from 'react';
import { useApiGet } from '../../lib/apiHooks';
import { TaskResult } from '../../lib/types';
import Loader from '../Loader';
import { Link } from '../../CommonJs/Nav';
import { formatDate } from '../../lib/ItpUtil';

interface TaskResultsProps
{
    taskId:number|null;
}

export default function TaskResults({
    taskId
}:TaskResultsProps){

    const results=useApiGet<TaskResult[]>(
        'Loading Results',
        taskId?`Task/${taskId}/Results`:null,
        null,
        [taskId]);

    if(!results){
        return <Loader/>
    }

    return (
        <div>
            <h3 className="mt-5">Task Results</h3>
            <ul className="list-group">
                {results.map((r,i)=>(
                    <li key={i} className="list-group-item fill-with-link">
                        <Link to={`/task/${taskId}/results/${r.Id}`}>
                            ID:{r.Id} - {formatDate(r.Created)}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );

}