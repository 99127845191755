import React from 'react';
import { LinkProps, Link } from '../CommonJs/Nav';
import { mergeClassNames } from '../CommonJs/utilTs';

interface IconLinkProps extends LinkProps
{
    children:any;
}

export default function IconLink({children,className,...props}:IconLinkProps)
{

    const cn=mergeClassNames(
        className,
        'btn btn-primary');

    return (
        <Link className={cn} {...props}>
            {children}
        </Link>
    )
}