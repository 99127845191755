import React from 'react';
import { Button } from 'react-bootstrap';
import { useApp } from '../lib/context';

export default function Settings()
{

    const app=useApp();

    return (
        <div>
            <h3 className="mb-4">Settings</h3>

            <ul className="list-group">
                <li className="list-group-item d-flex align-items-center justify-content-between">
                    Reset all app settings
                    <Button variant="danger" onClick={()=>app.hardReset()}>Reset</Button>
                </li>
            </ul>
            
        </div>
    )

}
