import { useApp, useAppAsync } from './context';
import { TaskQuestion, ItpTask, QuestionOption } from './types';
import { useState, useCallback, useMemo, DependencyList, useEffect } from 'react';
import { useUpdateProperty, useMounted } from '../CommonJs/utilTs';
import { TaskSummary } from './paramTypes';
import Log from '../CommonJs/Log';

export function useTask(id:number|null,renderOnSave:boolean):[ItpTask|null,()=>void]
{
    const {api}=useApp();
    const [,setUpdate]=useState<number>(0);

    useUpdateProperty(api,'tasks');

    const save=useCallback(()=>{
        if(renderOnSave){
            setUpdate(u=>u+1);
        }
        api.updateTaskWithDelayAsync(api.getTask(id,false));
    },[api,id,renderOnSave]);

    return [api.getTask(id,true),save];
}

export function useTaskSummary(taskId:number|null):TaskSummary|null
{
    const summary=useAppAsync(null,async ({api})=>{
        return taskId?await api.getTaskSummaryAsync(taskId):null;
    },[taskId]);
    return summary;
}

export interface ListController<TAdd>
{
    addItemAsync:(item:TAdd)=>Promise<void>;
    removeItem:(id:number)=>void;
}

export function useQuestion(id:number|null,renderOnSave:boolean):[TaskQuestion|null,()=>void]
{
    const {api}=useApp();
    const [,setUpdate]=useState<number>(0);

    useUpdateProperty(api,'questions');

    const save=useCallback(()=>{
        if(renderOnSave){
            setUpdate(u=>u+1);
        }
        api.updateQuestionWithDelayAsync(api.getQuestion(id,false));
    },[api,id,renderOnSave]);

    return [api.getQuestion(id,true),save];
}

export function useQuestions(taskId:number|null):[TaskQuestion[]|null,ListController<string>]
{
    const {api}=useApp();

    useUpdateProperty(api,'questions');

    const controller=useMemo(()=>{
        return {
            addItemAsync:async (type:string):Promise<void>=>{
                if(taskId!==null){
                    await api.addQuestionToTaskAsync({
                        Type:type,
                        TaskId:taskId
                    });
                }
            },
            removeItem:async (id:number)=>{
                await api.deleteQuestionAsync(id);
            }
        }
    },[api,taskId]);

    return [api.getQuestions(taskId,true),controller];
}

export function useQuestionOptions(questionId:number|null):
    [QuestionOption[]|null,ListController<null>]
{
    const {api}=useApp();
    const [updateId,setUpdateId]=useState(0);
    const value=useAppAsync(null,
        ({api})=>api.getQuestionOptionsAsync(questionId),
        [questionId,updateId]);
    

    const controller=useMemo(()=>{
        return {
            addItemAsync:async (_:null):Promise<void>=>{
                if(questionId!==null){

                    let order=0;
                    if(value){
                        for(let o of value){
                            if(o.Order>=order){
                                order=o.Order+1;
                            }
                        }
                    }

                    await api.addQuestionOptionAsync({
                        QuestionId:questionId,
                        Order:order
                    });
                    setUpdateId(i=>i+1);
                }
            },
            removeItem:async (id:number)=>{
                await api.deleteQuestionOptionAsync(id);
                setUpdateId(i=>i+1);
            }
        }
    },[api,questionId,value]);

    return [value,controller];
}

export function useApiGet<T>(uiMessage:string|null,uri:string|null,data:any,deps:DependencyList):T|null
{

    if(!deps){
        deps=[];
    }

    const {http}=useApp();
    const mounted=useMounted();
    const [value,setValue]=useState<T|null>(null);

    useEffect(()=>{
        setValue(null);
        if(!uri){
            return;
        }
        const work=async ()=>{
            try{
                const r=uiMessage?
                    await http.uiGetAsync(uiMessage,uri,data):
                    await http.getAsync(uri,data);
                if(mounted.mounted){
                    setValue(r as T|null);
                }
            }catch(ex){
                Log.error('GET '+uri+' failed',ex);
            }
            
        }
        if(uri){
            work();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[...deps,uiMessage,uri,http,mounted]);

    return value;

}