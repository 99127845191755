import React, { useCallback } from 'react';
import { useQuestion, useQuestionOptions } from '../../lib/apiHooks';
import Loader from '../Loader';
import TaskTypes from '../../lib/TaskTypes';
import { QuestionViewProps } from '../../lib/paramTypes';
import { ListGroup, Button } from 'react-bootstrap';
import FormGroup from '../FormGroup';
import Well from '../Well';
import { MdLayers, MdDelete } from 'react-icons/md';
import { iconSize } from '../../style/style';
import { setOptionOrder } from '../../lib/ItpUtil';
import { QuestionOption } from '../../lib/types';
import { useRender } from '../../CommonJs/utilTs';
import { useApp } from '../../lib/context';
import { useWindowSize } from '../../CommonJs/WindowSize';


function MultipleChoiceView({questionId}:QuestionViewProps)
{
    const [options,optionCtl]=useQuestionOptions(questionId);
    const render=useRender();

    const {api}=useApp();
    const saveOption=useCallback(async (option:QuestionOption,swap:QuestionOption|null)=>{
        render();
        const t1=api.updateQuestionOptionWithDelayAsync(option);
        const t2=swap?api.updateQuestionOptionWithDelayAsync(swap):null;
        await t1;
        if(t2){
            await t2;
        }
        render();
    },[api,render]);

    const {stack}=useWindowSize();

    if(!options){
        return null;
    }

    const empty=!options || options.length===0;

    return (
        <>
            <div className="alert alert-info my-4">Respondents will answer this question by selecting one of the following answers</div>
            <h3>Answers</h3>
            {empty?
                <Well>
                    <div>
                        No Answers Added yet
                    </div>
                    <MdLayers size={iconSize}/>
                </Well>
            :
                <ListGroup>
                    {options&&options.map((o,i)=>(
                        <ListGroup.Item className="d-flex p-0" key={i} style={{order:o.Order}}>
                            {!stack&&
                                <input type="number" className="form-control px-1 text-center"
                                    style={{width:'70px'}}
                                    value={o.Order+1}
                                    onChange={async (e)=>{
                                        const order=Number(e.target.value);
                                        const swap=setOptionOrder(isNaN(order)?0:order-1,o,options);
                                        saveOption(o,swap);
                                    }} />
                                }
                            <input type="text" className="form-control flex-fill m-0"
                                placeholder="Answer Value"
                                value={o.Text||''}
                                onChange={(e)=>{
                                    o.Text=(e.target.value||null) as any;
                                    saveOption(o,null);
                                }} />

                            <Button className="m-0" variant="danger" onClick={()=>optionCtl.removeItem(o.Id)}>
                                <MdDelete/>
                            </Button>
                            
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            }

            <Button className="mt-4" variant="primary" type="button" onClick={()=>optionCtl.addItemAsync(null)}>Add Answer</Button>
        </>
    );
}

function TextView(props:QuestionViewProps)
{
    return (
        <div className="alert alert-info my-4">Respondents will answer this question using an open text input</div>
    );
}

interface TaskQuestionProps
{
    questionId:number|null;
}

export default function TaskQuestion({
    questionId
}:TaskQuestionProps){
    const [question,changeMade]=useQuestion(questionId,true);
    
    if(!question){
        return <Loader/>
    }

    
    const viewProps:QuestionViewProps={
        questionId,
        question,
        changeMade
    };

    let includeText=true;
    let content:any;
    switch(question.Type){

        case TaskTypes.MultipleChoice:
            content=<MultipleChoiceView {...viewProps}/>
            break;

        case TaskTypes.Text:
            content=<TextView {...viewProps} />
            break;

        default:
            content=null;
    }

    return (
        <>
            <h3 className="mt-2">#{question.Order+1}</h3>
            <FormGroup label="Type" value={question.Type} readOnly={true} />
            <FormGroup label="Estimated Time (seconds)" value={question.EstimatedTime} onChange={v=>{
                const n=Number(v);
                if(isNaN(n)){
                    return;
                }
                question.EstimatedTime=n;
                changeMade();
            }}/>
            {includeText&&
                <FormGroup label="Question" value={question.Text||''} onChange={value=>{
                    question.Text=(value||null) as string;
                    changeMade();
                }}/>
            }
            {content}
        </>
    );
}