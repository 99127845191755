import React from 'react';
import './Tabs.scss';
import { MdBook, MdAccountCircle, MdLayers, MdAttachMoney } from 'react-icons/md';
import { useApp } from '../lib/context';
import { useHistory } from '../CommonJs/Nav';
import { useUpdateProperty } from '../CommonJs/utilTs';

interface TabItem
{
    label:string;
    to:string;
    active:RegExp;
    icon:any;
}

const items:TabItem[]=[
    {
        label:'Surveys',
        to:'/surveys',
        active:/^\/survey.*/i,
        icon:<MdBook/>
    },
    {
        label:'Payouts',
        to:'/payouts',
        active:/^\/payouts.*/i,
        icon:<MdAttachMoney/>
    },
    {
        label:'Tasks',
        to:'/tasks',
        active:/^\/task.*/i,
        icon:<MdLayers/>
    },
    {
        label:'Profile',
        to:'/profile',
        active:/^\/profile.*/i,
        icon:<MdAccountCircle/>
    }
];

export default function Tabs()
{
    const app=useApp();
    useUpdateProperty(app,'noTabsRefs');
    const {nav,noTabsRefs}=app;
    const history=useHistory();
    const path=history.location.pathname;

    return (
        <ul className={"main-tabs"+(noTabsRefs?' hidden':'')}>
            {items.map((item,i)=>{
                
                const active=item.active.test(path);

                return (
                    <li key={i} onClick={()=>nav.push(item.to)} className={active?'active':''}>
                        {item.icon}
                        <span>{item.label}</span>
                    </li>
                )
            })}
        </ul>
    )
}