import React from 'react';
import { useApiGet } from '../../lib/apiHooks';
import Loader from '../Loader';
import { Table } from 'react-bootstrap';
import { PayoutSummary } from '../../lib/paramTypes';
import { PayoutStatus } from '../../lib/types';
import { formatMoney } from '../../lib/ItpUtil';

export default function Payouts()
{

    const payouts=useApiGet<PayoutSummary[]>(
        'Loading Payouts',
        'Respondent/Payouts',
        null,
        []);
    
    if(!payouts){
        return <Loader/>
    }

    return (
        <Table striped bordered>
            <thead>
                <tr>
                    <th>Survey Name</th>
                    <th>Amount</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {payouts.map((p,i)=>{

                    return (
                        <tr key={i}>
                            <td>{p.TaskName}</td>
                            <td>{formatMoney(p.Amount)}</td>
                            <td>{PayoutStatus[p.Status]}</td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )
    
}