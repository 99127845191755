import React from 'react';
import { PublicQuestionViewProps } from '../../lib/paramTypes';
import QuestionBody from './QuestionBody';
import { ListGroup } from 'react-bootstrap';

export default function QuestionMultipleChoiceView({answer,updateAnswer,question}:PublicQuestionViewProps)
{

    const options=question.Options;

    return (
        <QuestionBody question={question}>
            <ListGroup>
                {options&&options.map((o,i)=>{

                    const active=answer.OptionId===o.Id;

                    return (
                        <ListGroup.Item key={i}
                            className={active?'active':''}
                            onClick={()=>updateAnswer(a=>{
                                a.OptionId=o.Id;
                                return a.OptionId?true:false;
                            })}>
                            {o.Text}
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </QuestionBody>
    )
}