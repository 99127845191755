import React, { useMemo, useCallback } from 'react';
import './TaskRunner.scss';
import Loader from '../Loader';
import { PublicItpTask, PublicQuestionViewProps, AnswerState, SubmitTaskResultRequest } from '../../lib/paramTypes';
import { useApiGet } from '../../lib/apiHooks';
import { Link } from '../../CommonJs/Nav';
import SlidePanel from '../../CommonJs/SlidePanel';
import { TaskQuestion, TaskAnswer } from '../../lib/types';
import TaskTypes from '../../lib/TaskTypes';
import QuestionTextView from './QuestionTextView';
import Log from '../../CommonJs/Log';
import { useRender } from '../../CommonJs/utilTs';
import QuestionMultipleChoiceView from './QuestionMultipleChoiceView';
import { Button } from 'react-bootstrap';
import { useApp, useNoTabs } from '../../lib/context';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';

const defaultViewType='question';

const logged:any={};

interface BaseQuestionViewProps
{
    task:PublicItpTask;
    question:TaskQuestion;
    state:AnswerState;
    changeMade:()=>void;
}

function BaseQuestionView({
    task,
    question,
    state,
    changeMade
}:BaseQuestionViewProps){
    useNoTabs();
    const {answer,answered}=state;
    const setAnswered=useCallback((answered:boolean)=>{
        state.answered=answered;
        changeMade();
    },[changeMade,state]);
    const updateAnswer=useCallback((update:(answer:TaskAnswer)=>boolean)=>{
        state.answered=update(answer);
        changeMade();
    },[answer,changeMade,state]);

    const shared:PublicQuestionViewProps={
        task,
        question,
        answer,
        answered,
        setAnswered,
        updateAnswer,
        changeMade
    };

    switch(question.Type){

        case TaskTypes.Text:
            return <QuestionTextView {...shared}  />

        case TaskTypes.MultipleChoice:
            return <QuestionMultipleChoiceView {...shared} />

        default:
            const msg=`Unknown question type. `+
                `Type: ${question.Type}, QuestionId:${question.Id}, TaskId:${task.Id}`;
            if(!logged[msg]){
                logged[msg]=true;
                Log.error(msg);
            }
            return <div className="alert alert-warning m-4">
                {msg}
            </div>
    }
}

interface TaskRunnerProps
{
    taskId:number|null;
    viewType:string|null;
    subId:number|null;
}

export default function TaskRunner({
    taskId,
    viewType,
    subId
}:TaskRunnerProps){


    if(!viewType){
        viewType=defaultViewType;
    }
    if(!subId){
        subId=1;
    }

    const {http,nav}=useApp();

    const render=useRender();

    const questionLinkBase=`/survey/${taskId}/question`;

    const task=useApiGet<PublicItpTask>(
        'Loading Survey',
        taskId?`Respondent/Task/${taskId}`:null,
        null,
        [taskId]);

    const answers=useMemo<AnswerState[]>(()=>{
        if(!task || !task.Questions){
            return [];
        }
        const answers=task.Questions.map(q=>{
            const answer:TaskAnswer={
                Id:0,

                QuestionId:q.Id,
                Question:null as any,

                ResultId:0,
                Result:null as any,

                Type:q.Type,

                Text:null as any,

                Media:[],

                OptionId:null,
                Option:null as any
            }
            return {answer,answered:false};
        });

        console.log(task);
        console.log(answers);

        return answers;
    },[task]);

    const submit=useCallback(async ()=>{

        if(!taskId || !answers){
            return;
        }

        const request:SubmitTaskResultRequest={
          TaskId:taskId,
          Answers:answers.map(a=>a.answer)
        };

        const r=await http.uiPostAsync(
            'Submitting Survey',
            'Respondent/Task',
            request);

        console.log('Survey Result',r);

        if(r){
            nav.push('/payouts');
        }

    },[http,nav,taskId,answers]);

    if(!task){
        return <Loader/>
    }

    const currentState=answers[subId-1];
    const nextActive=(currentState&&currentState.answered)?true:false;
    const backActive=subId>1;

    const questionViews=task.Questions.map((q,i)=>(
        <div className="task-runner-question-frame" key={i}>
            <BaseQuestionView task={task} question={q} state={answers[i]} changeMade={render}/>
        </div>
    ));

    questionViews.push(
        <div className="task-runner-question-frame align-items-center" key={'end'}>
            <h3 className="m-5">Complete</h3>
            <Button variant="primary" onClick={submit}>Submit</Button>
        </div>
    )
    

    return (
        <div className="task-runner">

            <div className="task-runner-main">
                <SlidePanel index={subId-1} itemsFlex={true}>
                    {questionViews}
                </SlidePanel>
            </div>
            
            <div className="task-runner-bottom">
                <div className="text-left">
                    <Link className={"btn btn-primary"+(backActive?' active':'')} to={`${questionLinkBase}/${subId-1}`}><FaCaretLeft/></Link>
                </div>

                <span>{subId} of {task.Questions.length}</span>

                <div className="text-right">
                    <Link className={"btn btn-primary"+(nextActive?' active':'')} to={`${questionLinkBase}/${subId+1}`}><FaCaretRight/></Link>
                </div>

            </div>
        </div>
    )
}