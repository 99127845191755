import React from 'react';
import { useApp, useAppState, useAppMutableAsync } from '../../lib/context';
import Loader from '../Loader';
import { Form, Button } from 'react-bootstrap';
import { Team } from '../../lib/types';
import FormGroup from '../FormGroup';
import { useLockScreen } from '../../CommonJs/LockScreen';
import { defaultNav } from '../../CommonJs/Nav';

interface TeamDetailProps
{
    id:number;
}

export default function TeamDetail({id}:TeamDetailProps)
{

    const create=id===0;

    const lock=useLockScreen();
    const {http}=useApp();
    const {orgId}=useAppState();

    const [item,setItem]=useAppMutableAsync(null,async ({http})=>{
        if(create){
            return null;
        }
        return await http.getSingleAsync('Team/'+id) as Team;
    },[id]);

    if(!item && !create){
        return <Loader/>
    }

    const submit=async (e)=>{
        e.preventDefault();
        lock.lock(create?'Creating Team':'Updating Team');
        try{

            const d={...item} as any;
            d.OrganizationId=orgId;

            if(create){
                const team=await http.postAsync<Team>('Team',d);
                defaultNav.push('team/'+team.Id);
            }else{
                await http.putAsync('Team/'+item!.Id,d);
            }

        }finally{
            lock.unlock();
        }
    }

    return (
        <Form onSubmit={submit}>
            <FormGroup label="Name" type="text" value={item} propName="Name" setter={setItem} />
            <Button variant="primary" type="submit">{create?'Create':'Save'}</Button>
        </Form>
    )
}