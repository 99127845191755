import React, { HTMLAttributes } from 'react';
import { mergeClassNames } from '../CommonJs/utilTs';


interface WellProps extends HTMLAttributes<HTMLDivElement>
{

    children?:any;
}

export default function Well({children,className,...props}:WellProps)
{

    return (
        <div {...props} className={mergeClassNames(
            className,"well mv-4 p-4 d-flex flex-row justify-content-between align-items-center")}>
            {children}
        </div>
    )
}