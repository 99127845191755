import React from 'react';
import {NavRoute, Link} from '../../CommonJs/Nav';
import Dashboard from './Dashboard';
import Tasks from './Tasks';
import Task from './Task';
import TaskCreate from './TaskCreate';
import SignIn from '../SignIn';
import Page from '../Page';
import Profile from './Profile';
import MainLayout from '../MainLayout';
import Teams from './Teams';
import TeamDetail from './TeamDetail';
import { useSignIn } from '../../lib/context';
import RespondentTaskList from '../Respondent/RespondentTaskList';
import TaskSummaryView from '../Respondent/TaskSummaryView';
import TaskRunner from '../Respondent/TaskRunner';
import Payouts from '../Respondent/Payouts';
import Settings from '../Settings';

const links=<>
    <li><Link to="/">Dashboard</Link></li>
</>

export default function Portal(props:any){

    const signIn=useSignIn();

    if(!signIn){
        return <SignIn />
    }

    return (
        <MainLayout className="layout-portal" links={links}>

            <NavRoute path="/">
                <Page>
                    <Dashboard />
                </Page>
            </NavRoute>

            <NavRoute path="/tasks">
                <Page>
                    <Tasks />
                </Page>
            </NavRoute>

            <NavRoute path="/task/create">
                <Page>
                    <TaskCreate />
                </Page>
            </NavRoute>

            <NavRoute match={/^\/task\/(\d+)\/?(\w+)?\/?(\w+)?$/} render={r=>(
                <Page container={false}>
                    <Task
                        taskId={r.numberMatch(1)}
                        viewName={r.stringMatch(2)}
                        subId={r.numberMatch(3)} />
                </Page>
            )}/>

            <NavRoute path="/teams">
                <Page>
                    <Teams />
                </Page>
            </NavRoute>

            <NavRoute path="/team/create">
                <Page>
                    <TeamDetail id={0} />
                </Page>
            </NavRoute>

            <NavRoute match={/^\/team\/(\d+)$/} render={r=>(
                <Page>
                    {r.success&&<TeamDetail id={Number(r.matches![1])} />}
                </Page>
            )}/>

            <NavRoute path="/profile">
                <Page container={false}>
                    <Profile />
                </Page>
            </NavRoute>

            <NavRoute path="/surveys">
                <Page>
                    <RespondentTaskList/>
                </Page>
            </NavRoute>

            <NavRoute path="/payouts">
                <Page noStackPadding>
                    <Payouts/>
                </Page>
            </NavRoute>

            <NavRoute match={/^\/survey-summary\/(\d+)$/} render={r=>(
                <Page>
                    <TaskSummaryView taskId={Number(r.numberMatch(1))} />
                </Page>
            )}/>

            <NavRoute match={/^\/survey\/(\d+)\/?(\w+)?\/?(\d+)?$/} render={r=>(
                <Page container={false}>
                    <TaskRunner
                        taskId={Number(r.numberMatch(1))}
                        viewType={r.stringMatch(2)}
                        subId={r.numberMatch(3)}/>
                </Page>
            )}/>

            <NavRoute path="/settings">
                <Page>
                    <Settings/>
                </Page>
            </NavRoute>

        </MainLayout>
    );
}