import React, { useEffect, useState } from 'react';
import './App.scss';
import { ItpAppContext } from '../lib/context';
import Portal from './Portal/Portal';
import ItpApp from '../lib/ItpApp';
import config from '../config';
import LockScreenOverlay from '../CommonJs/LockScreenOverlay';

// const apps: AppSelector[] = [
//     new AppSelector('Respondent', 0, n => import('./Respondent')),
//     new AppSelector('Portal', 0, n => import('./Portal')),
// ];

export default function App()
{
    const [isInited,setIsInited]=useState(false);
    const [app]=useState(()=>{
        const a=new ItpApp(config);
        console.log('ItpApp',a);
        return a;
    });

    useEffect(() => {
        let m=true;
        const init=async ()=>{
            await app.initAsync();
            if(m){
                setIsInited(true);
            }
        }
        init();
        return ()=>{
            m=false;
            app.dispose();
        }
    }, [app]);

    useEffect(()=>{
        document.addEventListener('touchmove', function (event:any) {
            if (event.scale && event.scale !== 1) { event.preventDefault(); }
          }, { passive: false });
    },[]);

    if(!isInited){
        return null;
    }

    return (
        <ItpAppContext.Provider value={app}>
        <LockScreenOverlay lockScreen={app.lockScreen}>
            {/* <SelectApp apps={apps} /> */}
            <Portal />
        </LockScreenOverlay>
        </ItpAppContext.Provider>
    );
}