import ItpApp from './ItpApp';
import Http from '../CommonJs/Http';
import { User, Organization, ItpTask, OrganizationRole, TeamRole, TaskQuestion, QuestionMedia, QuestionOption } from './types';
import { UserProfileUpdate, TaskSummary, CreateTaskRequest, AddQuestionRequest, AddQuestionOptionRequest } from './paramTypes';
import { delayAsync } from '../CommonJs/utilTs';
import Log from '../CommonJs/Log';
import EventEmitterEx from '../CommonJs/EventEmitterEx';

const defaultApiVersion='0.0.0';

enum QueryState{
    running = 1,
    complete = 2
}

export default class ItpApi extends EventEmitterEx
{

    readonly app:ItpApp;

    readonly http:Http;

    private _apiVersion:string=defaultApiVersion;

    readonly questions:{[id:number]:TaskQuestion}={};

    readonly tasks:{[id:number]:ItpTask}={};

    private readonly queries:{[key:string]:QueryState}={};

    constructor(app:ItpApp)
    {
        super();
        this.app=app;
        this.http=app.http;
    }
    

    async getApiVersionAsync():Promise<string>{

        if(this._apiVersion!==defaultApiVersion){
            return this._apiVersion;
        }
        this._apiVersion=(await this.http.uiGetAsync('Getting Application Version','version'))||'0.0.0';
        return this._apiVersion;
    }

    getUserSelfAsync():Promise<User|null>{

        return this.http.uiGetAsync('Loading User Profile','User/Self');

    }

    updateUserProfileAsync(profile:UserProfileUpdate):Promise<User|null>
    {
        return this.http.uiPostAsync('Updating User Profile','User/Update',profile);
    }

    getUserOrganizationsAsync(role:OrganizationRole|null=null):Promise<Organization[]|null>{
        return this.http.uiGetAsync('Loading User Organizations','User/Organizations',{role});
    }

    getUserTeamsAsync(role:TeamRole|null=null):Promise<Organization[]|null>{
        return this.http.uiGetAsync('Loading User Teams','User/Teams',{role});
    }

    getTaskSummariesAsync():Promise<TaskSummary[]|null>
    {
        return this.http.uiGetAsync('Loading Task Summaries','Task/Summaries');
    }

    getTaskSummaryAsync(id:number):Promise<TaskSummary|null>
    {
        return this.http.uiGetAsync('Loading Task Summary','Task/Summary/'+id);
    }

    createTaskAsync(request:CreateTaskRequest):Promise<ItpTask|null>
    {
        return this.http.uiPostAsync('Creating Task','Task',request);
    }

    getTaskQuestionMediaAsync(id:number):Promise<QuestionMedia[]|null>
    {
        return this.http.uiGetAsync('Loading Task Media',`Task/${id}/Questions/Media`);
    }

    async updateTaskNameWithDelayAsync(id:number, name: string):Promise<boolean> {
        if(!await this.waitInQueueAsync(`task.${id}.name`)){
            return false;
        }

        try{
            await this.http.putAsync('Task/Name',{id,name});
        }catch(ex){
            Log.error('Update Task name failed',ex);
        }

        return true;
    }



    async getTaskAsync(id:number|null):Promise<ItpTask|null>
    {

        if(id===null){
            return null;
        }

        const r=await this.runQueryAsync(
            `Task/${id}`,
            (h,q)=>h.uiGetAsync<ItpTask>('Loading Task',q));

        if(r){
            this.tasks[r.Id]=r;
            this.emitProperty(this,'tasks');
        }

        return r;
    }

    getTask(id:number|null,autoLoad:boolean=true):ItpTask|null
    {
        return this.getObj<ItpTask>(id,autoLoad,this.tasks,()=>this.getTaskAsync(id));
    }

    async updateTaskWithDelayAsync(task:ItpTask|null):Promise<ItpTask|null> {
        if(!task){
            return null;
        }
        
        if(!await this.waitInQueueAsync(`task.${task.Id}`)){
            return null;
        }

        try{
            return await this.http.putAsync<ItpTask>('Task',task);
        }catch(ex){
            Log.error('Update Task name failed',ex);
            return null;
        }

    }


    //  Questions

    async getQuestionsAsync(taskId:number):Promise<TaskQuestion[]|null>
    {
        const r=await this.runQueryAsync(
            `Task/${taskId}/Questions`,
            (h,q)=>h.uiGetAsync<TaskQuestion[]>('Loading Task Questions',q));

        if(r && r.length){
            for(let q of r){
                this.questions[q.Id]=q;
            }
            this.emitProperty(this,'questions');
        }

        return r;
    }

    async getQuestionAsync(id:number|null):Promise<TaskQuestion|null>
    {

        if(id===null){
            return null;
        }

        const q=await this.runQueryAsync(
            `Task/Question/${id}`,
            (h,q)=>h.uiGetAsync<TaskQuestion>('Loading Question',q));

        if(q){
            this.questions[q.Id]=q;
            this.emitProperty(this,'questions');
        }

        return q;
    }

    getQuestion(id:number|null,autoLoad:boolean=true):TaskQuestion|null
    {
        return this.getObj<TaskQuestion>(id,autoLoad,this.questions,()=>this.getQuestionAsync(id));
    }

    getQuestions(taskId:number|null,autoLoad:boolean=true):TaskQuestion[]|null
    {
        if(taskId===null){
            return null;
        }
        const query=`Task/${taskId}/Questions`;
        const state=this.queries[query];
        if(autoLoad && state!==QueryState.complete){
            if(!state){
                this.getQuestionsAsync(taskId);
            }
            return null;
        }
        const qs:TaskQuestion[]=[];
        for(let e in this.questions){
            const q=this.questions[e];
            if(q.TaskId===taskId){
                qs.push(q);
            }
        }
        return qs;
    }

    async updateQuestionWithDelayAsync(question:TaskQuestion|null):Promise<TaskQuestion|null> {
        if(!question){
            return null;
        }
        
        if(!await this.waitInQueueAsync(`question.${question.Id}`)){
            return null;
        }

        try{
            return await this.http.putAsync<TaskQuestion>('Task/Question',question);
        }catch(ex){
            Log.error('Update Task name failed',ex);
            return null;
        }

    }

    async addQuestionToTaskAsync(request:AddQuestionRequest):Promise<TaskQuestion|null>
    {
        const q=await this.http.uiPostAsync<TaskQuestion|null>(
            'Adding Question to Task','Task/Question',request);
        if(q){
            this.questions[q.Id]=q;
            this.emitProperty(this,'questions');
        }

        return q;
    }

    async deleteQuestionAsync(questionId:number|null)
    {
        if(questionId===null){
            return;
        }

        await this.http.uiDeleteAsync('Removing Question','Task/Question/'+questionId);

        delete this.questions[questionId];
        this.emitProperty(this,'questions');
    }




    // options
    async getQuestionOptionsAsync(questionId:number|null):Promise<QuestionOption[]|null>
    {
        if(questionId===null){
            return null;
        }
        return await this.http.uiGetAsync(
            'Loading Question Options',
            `Task/Question/${questionId}/Options`);
    }


    async addQuestionOptionAsync(request:AddQuestionOptionRequest): Promise<QuestionOption|null> {

        const r=await this.http.uiPostAsync<QuestionOption>(
            'Adding Option',
            'Task/Question/Options',
            request);

        return r;
    }

    async updateQuestionOptionWithDelayAsync(option:QuestionOption):Promise<QuestionOption|null>
    {
        if(!option){
            return null;
        }
        
        if(!await this.waitInQueueAsync(`QuestionOption.${option.Id}`)){
            return null;
        }

        try{
            return await this.http.putAsync<QuestionOption>('Task/Question/Option',option);
        }catch(ex){
            Log.error('Update Task name failed',ex);
            return null;
        }
    }

    async deleteQuestionOptionAsync(optionId:number|null)
    {
        if(optionId===null){
            return;
        }

        await this.http.uiDeleteAsync('Removing Option','Task/Question/Option/'+optionId);
    }



    // util


    private updateIds:{[key:string]:number}={};

    private queueDelay:number=1500;

    private async waitInQueueAsync(key:string):Promise<boolean>
    {
        let id=this.updateIds[key];
        if(id===undefined){
            id=0;
        }
        id++;
        this.updateIds[key]=id;
        await delayAsync(this.queueDelay);
        return this.updateIds[key]===id;
    }

    private async runQueryAsync<T>(query:string, work:(http:Http,query:string)=>Promise<T>):Promise<T>
    {
        let success=false;
        this.queries[query]=QueryState.running;
        try{
            const r=await work(this.http,query);
            this.queries[query]=QueryState.complete;
            success=true;
            return r;
        }finally{
            if(!success){
                delete this.queries[query];
            }
        }
    }

    private getObj<T>(
        id:number|null,
        autoLoad:boolean,
        cache:{[id:number]:T},
        loadAsync:()=>Promise<any>
    ):T|null{

        if(id===null){
            return null;
        }
        const r=cache[id]||null;
        if(!r && autoLoad){
            loadAsync();
        }
        return r;
    }
}