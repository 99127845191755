export interface AppState
{

    userId:string|null;

    /** The Id of the current organization selected */
    orgId:number|null;

    /** The Id of the current team selected */
    teamId:number|null;

}

export function createDefaultAppState():AppState{
    return {
        userId:null,
        orgId:null,
        teamId:null
    }
}