


export interface Respondent
{
    Id:number;

    BirthDate:string|Date;

    TaskResults:TaskResult[];

    IsAnonymous:boolean;

    ShowClientInfo:boolean;

    IsBanded:boolean;

    Payouts:RespondentPayout[];


}



export interface ItpTask
{
    Id:number;

    Name:string;

    Description:string;

    TeamId:number;
    Team:Team;

    Results:TaskResult[];

    Questions:TaskQuestion[];

    Tags:Tag[];

    Status:ItpTaskStatus;

    TemplateId:number|null;
    Template:TaskTemplate;

    Payout:number;

    RowVersion?:string|Date|null;


}



export interface Organization
{
    Id:number;

    Name:string;

    DefaultUserId:number|null;
    DefaultUser:User;

    Subscriptions:ClientSubscription[];

    Members:OrganizationMember[];

    Teams:Team[];

    AutoRegisterDomains:AutoRegisterDomain[];


}



export interface TaskTemplate
{
    Id:number;

    Name:string;

    IsPublic:boolean;

    Teams:TeamTaskTemplate[];

    DerivedTasks:ItpTask[];

    TaskQuestions:TaskQuestion[];

    TaskName:string;

    TaskDescription:string;

    TaskPayout:number|null;


}



export interface TaskResult
{
    Id:number;

    RespondentId:number;
    Respondent:Respondent;

    Payouts:RespondentPayout[];

    TaskId:number;
    Task:ItpTask;

    Status:TaskResultStatus;

    Answers:TaskAnswer[];

    Created:string|Date;


}



export interface MediaItem
{
    Id:number;

    UUID:string;

    ContentType:string;

    QuestionOptions:QuestionOptionMedia[];

    TaskAnswers:AnswerMedia[];

    TaskQuestions:QuestionMedia[];

    SourceId:number;
    Source:MediaSource;

    RowVersion?:string|Date|null;


}



export interface TaskQuestion
{
    Id:number;

    Order:number;

    TaskId:number|null;
    Task:ItpTask;

    TemplateId:number|null;
    Template:TaskTemplate;

    Type:string;

    Answers:TaskAnswer[];

    Options:QuestionOption[];

    Media:QuestionMedia[];

    EstimatedTime:number;

    Text:string;

    Data1:string;

    Data2:string;

    Data3:string;

    Data4:string;

    Data5:string;

    Blob:string;

    RowVersion?:string|Date|null;


}



export interface TaskAnswer
{
    Id:number;

    QuestionId:number;
    Question:TaskQuestion;

    ResultId:number;
    Result:TaskResult;

    Type:string;

    Text:string;

    Media:AnswerMedia[];

    OptionId:number|null;
    Option:QuestionOption;


}



export interface Team
{
    Id:number;

    Name:string;

    Members:TeamMember[];

    OrganizationId:number;
    Organization:Organization;

    AutoRegisterDomains:AutoRegisterDomain[];

    Templates:TeamTaskTemplate[];

    Tasks:ItpTask[];

    IsOrganizationDefault:boolean;

    DesignRequests:TaskDesignRequest[];

    SupportRequests:SupportRequest[];


}



export interface TeamMember
{
    Id:number;

    Role:TeamRole;

    UserId:number;
    User:User;

    TeamId:number;
    Team:Team;


}



export interface User
{
    Id:number;

    Email:string;

    FirstName:string;

    LastName:string;

    OrganizedMeetings:Meeting[];

    MeetingInvites:MeetingInvite[];

    DefaultOrganizationId:number;
    DefaultOrganization:Organization;

    OrganizationMemberships:OrganizationMember[];

    RespondentId:number|null;
    Respondent:Respondent;

    Status:UserStatus;

    Gender:Gender;

    StaffId:number|null;
    Staff:Staff;

    ClientId:number|null;
    Client:Client;

    Memberships:TeamMember[];

    Roles:string;

    Complaints:Complaint[];

    ReportedComplaints:Complaint[];

    SupportRequests:SupportRequest[];

    DesignRequests:TaskDesignRequest[];

    UUID:string;

    Phone:string;

    Created:string|Date;

    InstagramName:string;

    SuspendUntil:string|Date|null;

    PasswordHash:string;


}



export enum TeamRole
{
    None=0,
    View=1,
    Manage=2,
    Admin=4,

}



export interface Client
{
    Id:number;


}



export interface Staff
{
    Id:number;

    Role:StaffRole;


}



export interface OrganizationMember
{
    Id:number;

    UserId:number;
    User:User;

    Role:OrganizationRole;

    OrganizationId:number;
    Organization:Organization;


}



export interface Complaint
{
    Id:number;

    Type:ComplaintType;

    Description:string;

    UserId:number;
    User:User;

    ReporterId:number;
    Reporter:User;


}



export enum ComplaintType
{
    Other=0,
    BadLanguage=1,

}



export interface SupportRequest
{
    Id:number;

    RequesterId:number;
    Requester:User;

    Type:SupportRequestType;

    Comment:string;

    Urgency:SupportUrgencyLevel;

    View:string;

    TeamId:number|null;
    Team:Team;

    Status:SupportStatus;

    BotId:number;
    Bot:SupportBot;


}



export enum SupportRequestType
{
    Unknown=0,
    Other=1,
    TaskCreation=2,

}



export interface TaskDesignRequest
{
    Id:number;

    RequesterId:number;
    Requester:User;

    TeamId:number;
    Team:Team;


}



export enum SupportUrgencyLevel
{
    Low=0,
    Medium=1,
    Hight=2,
    Critical=3,

}



export enum TaskResultStatus
{
    InProgress=0,
    Abandoned=1,
    Complete=2,

}



export interface TeamTaskTemplate
{
    Id:number;

    TeamId:number;
    Team:Team;

    TemplateId:number;
    Template:TaskTemplate;


}



export interface ClientSubscription
{
    Id:number;

    OrganizationId:number;
    Organization:Organization;

    Created:string|Date;

    PlanId:number;
    Plan:SubscriptionPlan;

    Status:SubscriptionStatus;

    PaymentInterval:PaymentInterval;

    Payments:ClientPayment[];

    PaymentMethodId:number;
    PaymentMethod:PaymentMethod;


}



export interface ClientPayment
{
    Id:number;

    Date:string|Date;

    SubscriptionId:number|null;
    Subscription:ClientSubscription;

    Amount:number;

    MethodId:number;
    Method:PaymentMethod;

    Status:PaymentStatus;


}



export interface SubscriptionPlan
{
    Id:number;

    Name:string;

    Monthly:number;

    Annual:number;

    Subscribers:ClientSubscription[];

    MaxTeamSize:number;

    MaxTeamCount:number;

    Features:SubscriptionPlanFeature[];


}



export enum PaymentInterval
{
    None=0,
    Monthly=1,
    Annual=2,

}



export enum SubscriptionStatus
{
    Canceled=0,
    Suspended=1,
    Active=2,

}



export enum OrganizationRole
{
    None=0,
    View=1,
    Manage=2,
    Admin=4,
    Accounting=8,

}



export interface PaymentMethod
{
    Id:number;

    Name:string;

    Subscriptions:ClientSubscription[];

    Payments:ClientPayment[];

    GatewayId:number;
    Gateway:PaymentGateway;

    StripeCustomerId:string;


}



export interface PaymentGateway
{
    Id:number;

    Name:string;

    PaymentMethods:PaymentMethod[];


}



export enum PaymentStatus
{
    Pending=0,
    AttentionNeeded=1,
    Failed=3,
    Complete=4,

}



export enum UserStatus
{
    Active=0,
    Suspended=1,
    Band=2,

}



export interface RespondentPayout
{
    Id:number;

    UUID:string;

    RespondentId:number;
    Respondent:Respondent;

    TaskResultId:number;
    TaskResult:TaskResult;

    GatewayId:number;
    Gateway:PayoutGateway;

    Status:PayoutStatus;

    Amount:number;


}



export enum PayoutStatus
{
    Pending=0,
    AttentionNeeded=1,
    Failed=3,
    Complete=4,

}



export interface PayoutGateway
{
    Id:number;

    Name:string;

    Payouts:RespondentPayout[];


}



export interface Feature
{
    Id:number;

    UUID:string;

    Name:string;

    Description:string;

    Plans:SubscriptionPlanFeature[];


}



export enum StaffRole
{
    None=0,
    View=1,
    Manage=2,
    Admin=4,
    Accounting=8,

}



export interface Setting
{
    Key:string;

    Value:string;


}



export interface MediaSource
{
    Id:number;

    Name:string;

    Items:MediaItem[];


}



export interface Meeting
{
    Id:number;

    Time:string|Date;

    Length:string;

    Invites:MeetingInvite[];

    OrganizerId:number;
    Organizer:User;


}



export interface MeetingInvite
{
    Id:number;

    MeetingId:number;
    Meeting:Meeting;

    UserId:number;
    User:User;

    Status:MeetingStatus;

    ProposedTime:string|Date|null;

    Comment:string;


}



export enum MeetingStatus
{
    Pending=0,
    Accepted=1,
    Declined=2,
    Conflict=3,

}



export enum SupportStatus
{
    Open=0,
    WorkingWithBot=1,
    AssistanceNeeded=2,
    WorkingWithStaff=3,
    Unresolvable=4,
    SolvedByBot=5,
    SolvedByStaff=6,

}



export interface SupportBot
{
    Id:number;

    SupportRequests:SupportRequest[];


}



export interface AutoRegisterDomain
{
    Id:number;

    Domain:string;

    OrganizationId:number;
    Organization:Organization;

    TeamId:number;
    Team:Team;


}



export enum Gender
{
    None=0,
    Male=1,
    Female=2,
    NonBinary=4,
    Fluid=8,
    Trans=16,
    Other=32,

}



export interface Tag
{
    Id:number;

    Value:string;

    TaskId:number;
    Task:ItpTask;


}



export interface SubscriptionPlanFeature
{
    Id:number;

    SubscriptionPlanId:number;
    SubscriptionPlan:SubscriptionPlan;

    FeatureId:number;
    Feature:Feature;


}



export interface QuestionMedia
{
    Id:number;

    RefId:number;
    Ref:TaskQuestion;

    MediaId:number;
    Media:MediaItem;


}



export interface AnswerMedia
{
    Id:number;

    RefId:number;
    Ref:TaskAnswer;

    MediaId:number;
    Media:MediaItem;


}



export interface QuestionOption
{
    Id:number;

    QuestionId:number;
    Question:TaskQuestion;

    Answers:TaskAnswer[];

    Type:string;

    Text:string;

    Order:number;

    Media:QuestionOptionMedia[];

    RowVersion?:string|Date|null;


}



export interface QuestionOptionMedia
{
    Id:number;

    RefId:number;
    Ref:QuestionOption;

    MediaId:number;
    Media:MediaItem;


}



export enum ItpTaskStatus
{
    NotActive=0,
    Active=1,
    Archived=2,

}
