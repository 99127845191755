import React from 'react';
import { Card } from 'react-bootstrap';
import { /*MdPeople, MdGroupWork, MdSubscriptions,*/ MdLayers, MdAccountCircle, MdAttachMoney, MdBook } from 'react-icons/md';
import { Link } from '../../CommonJs/Nav';
import { iconSize } from '../../style/style';

export default function Dashboard()
{

    const items=[
        {
            head:'Surveys',
            icon:<MdBook size={iconSize}/>,
            body:'Take surveys for cold hard cash',
            bottom:<Link btnType="primary" to="/surveys">Task a Survey</Link>
        },
        {
            head:'Payouts',
            icon:<MdAttachMoney size={iconSize}/>,
            body:'Money in the BANK',
            bottom:<Link btnType="primary" to="/payouts">View What's Owed to You</Link>
        },
        {
            head:'Profile',
            icon:<MdAccountCircle size={iconSize}/>,
            body:'View your user profile',
            bottom:<Link btnType="primary" to="/profile">View Profile</Link>
        },
        {
            head:'Tasks',
            icon:<MdLayers size={iconSize}/>,
            body:'View active and archived tasks',
            bottom:<Link btnType="primary" to="/tasks">View Tasks</Link>
        },
        // {
        //     head:'Teams',
        //     icon:<MdPeople size={iconSize}/>,
        //     body:'Manage team members',
        //     bottom:<Link btnType="primary" to="/teams">View Teams</Link>
        // },
        // {
        //     head:'Organizations',
        //     icon:<MdGroupWork size={iconSize}/>,
        //     body:'Managed the organizations you belong to',
        //     bottom:<Link btnType="primary" to="/organizations">View Organizations</Link>
        // },
        // {
        //     head:'Subscriptions',
        //     icon:<MdSubscriptions size={iconSize}/>,
        //     body:'Manage your subscriptions',
        //     bottom:<Link btnType="primary" to="/subscriptions">View Subscriptions</Link>
        // },
    ];

    return (
        <div>

            {items.map(({head,icon,body,bottom},index)=>(
                <Card key={index} className="mb-5 mx-auto" style={{maxWidth:'500px'}}>
                    <Card.Header className="d-flex flex-row justify-content-between align-items-center"><h3>{head}</h3> {icon}</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {body}
                        </Card.Text>
                        {bottom}
                    </Card.Body>
                </Card>
            ))}

        </div>
    )

}