const TaskTypes = {
    MultipleChoice:"MultipleChoice",
    Text:"Text"
}

export const TaskTypesAry:string[]=[];

for(let t in TaskTypes){
    TaskTypesAry.push(TaskTypes[t]);
}

export default TaskTypes;