import React from 'react';
import { PublicQuestionViewProps } from '../../lib/paramTypes';
import QuestionBody from './QuestionBody';

export default function QuestionTextView({answer,updateAnswer,question}:PublicQuestionViewProps)
{

    return (
        <QuestionBody question={question}>
            <input
                className="form-control"
                placeholder="Enter you answer here." 
                value={answer.Text||''}
                onChange={e=>updateAnswer(a=>{
                    a.Text=e.target.value;
                    return a.Text?true:false;
                })} />
        </QuestionBody>
    )
}