import React, { useCallback, useState, useEffect } from 'react';
import Portal from '../CommonJs/Portal';
import './Modal.scss';
import { MdClose } from 'react-icons/md';


export interface ModalProps
{
    isOpen:boolean;
    fullWidth?:boolean;
    noHPadding?:boolean;
    closeRequested?:((closeValue?:boolean)=>void)|any;
    children?:any;
    closeOnBgClick?:boolean;
    hideClose?:boolean;
    closeButton?:string|boolean;
}

export default function Modal({
        isOpen,
        fullWidth,
        noHPadding=false,
        closeRequested,
        children,
        closeButton,
        hideClose,
        closeOnBgClick=true
    }:ModalProps)
{


    if(fullWidth){
        closeOnBgClick=false;
    }

    const [render,setRender]=useState(isOpen);
    useEffect(()=>{
        let active=true;

        if(isOpen){
            setRender(true);
        }else{
            setTimeout(()=>{
                if(active){
                    setRender(false);
                }
            },800);
        }

        return ()=>{active=false;}
    },[isOpen]);

    const onClick=useCallback((e:any)=>{
        if(closeOnBgClick && closeRequested && e.target.classList.contains('imp-modal')){
            closeRequested(false);
        }
    },[closeRequested,closeOnBgClick]);

    const callClose=useCallback(()=>{
        if(closeRequested){
            closeRequested(false);
        }
    },[closeRequested]);

    return (
        <Portal
            className={
                "imp-modal imp-modal-"+
                (isOpen?'open':'closed')+
                (fullWidth?' modal-wide':' narrow')+
                (noHPadding?' no-h-padding':'')}
            onClick={onClick}>
            <div className="container imp-modal-body">
                <div className="d-flex flex-column imp-modal-head">
                    {!hideClose&&<div className="text-right">
                        <button onClick={callClose} className="btn-close btn-min">
                            <MdClose className="icon-s" size="18px" />
                        </button>
                    </div>}
                    <div className="imp-modal-content">
                        {render&&children}
                        {closeButton&&
                            <button className="mt-5 btn btn-primary" onClick={callClose}>
                                {(typeof closeButton)==='string'?closeButton:'Close'}
                            </button>
                        }
                    </div>
                </div>
            </div>
        </Portal>
    )
}