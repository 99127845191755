import React from 'react';
import util from '../CommonJs/util';
import './Page.scss';

interface PageProps
{
    container?:boolean;
    className?:string;
    children?:any;
    noStackPadding?:boolean;
}

export default function Page({
    container=true,
    className,
    children,
    noStackPadding
    }:PageProps)
{

    return (
        <div className={util.mergeClassNames(
                className,
                "page"+
                (container?' scrollable':'')+
                (noStackPadding?' no-stack-padding':''))}>
            <div className={"page-content nav-trans-target"+(container?' container':'')}>
                {children}
            </div>
        </div>
    )

}