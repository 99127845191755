import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Link } from '../CommonJs/Nav';

interface EmptyProps
{
    children?:any;
    title:any;
    button?:any;
    onButtonClick?:()=>void,
    to?:string;
}

export default function Empty({title,children,button,to,onButtonClick}:EmptyProps)
{

    return (
        <div>
            <Alert variant="secondary">
                <Alert.Heading>{title}</Alert.Heading>
                {children}
                {(button && !to)&&<>
                    <hr/>
                    <div className="d-flex justify-content-end">
                        <Button onClick={onButtonClick} variant="outline-secondary">
                            {button}
                        </Button>
                    </div>
                </>}
                {to&&<>
                    <hr/>
                    <div className="d-flex justify-content-end">
                        <Link to={to} btnType="btn btn-outline-secondary">
                            {button}
                        </Link>
                    </div>
                </>}
            </Alert>
        </div>
    )
}