import React from 'react';
import { useApiGet } from '../../lib/apiHooks';
import { TaskAnswer } from '../../lib/types';
import Loader from '../Loader';

interface TaskResultProps
{
    taskId:number|null;
    resultId:number|null;
}

export default function TaskResult({
    taskId,
    resultId,
}:TaskResultProps){

    const answers=useApiGet<TaskAnswer[]>(
        'Loading Answers',
        `Task/Result/${resultId}/Answers`,
        null,
        [resultId]);

    if(!answers){
        return <Loader/>
    }

    return (
        <div>
            <h3 className="mt-5">Task Result: {resultId}</h3>
            <ul className="list-group">
                {answers.map((a,i)=>(
                    <li key={i} className="list-group-item">
                        <h4>#{i+1}</h4>
                        <p>
                            Q. {a.Question.Text}
                        </p>
                        <p>
                            A. {a.Option?a.Option.Text:a.Text}
                        </p>

                    </li>
                ))}
            </ul>
        </div>
    )

}
