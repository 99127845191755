import React from 'react';
import { TaskQuestion } from '../../lib/types';
import QuestionHeader from './QuestionHeader';
import ScrollView from '../../CommonJs/ScrollView';

interface QuestionBodyProps
{
    includeHeader?:boolean;
    question:TaskQuestion;
    children:any;
}

export default function QuestionBody({
    includeHeader=true,
    question,
    children
}:QuestionBodyProps)
{

    return (
        <ScrollView absolute={true}>
            <div className="task-runner-body">
                    {includeHeader&&<QuestionHeader question={question} />}
                    <div className="task-runner-content">
                        {children}
                    </div>
            </div>
        </ScrollView>
    )
}