import { QuestionOption } from './types';
import accounting from 'accounting';

export function setOptionOrder(order:number,option:QuestionOption,options:QuestionOption[]):QuestionOption|null
{
    let swap:QuestionOption|null=null;
    if(options){
        for(let o of options){
            if(o.Order===order && o!==option){
                o.Order=option.Order;
                swap=o;
                break;
            }
        }
    }
    option.Order=order;
    return swap;
}

export function formatMoney(value:number):string
{
    return accounting.formatMoney(value);
}

export function formatDate(date:string|Date|null):string
{
    if(!date){
        return '(Unknown Date)';
    }

    if(typeof date === 'string'){
        if(date.indexOf('+')===-1){
            const t=new Date(date+'+0000');
            if(Number.isNaN(t.getTime())){
                date=new Date(date);
            }else{
                date=t;
            }
        }
    }

    return date.toLocaleString();
}