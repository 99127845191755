import React from 'react';
import { TaskQuestion } from '../../lib/types';

interface QuestionHeaderProps
{
    question:TaskQuestion;
}

export default function QuestionHeader({question}:QuestionHeaderProps)
{

    return (
        <h3 className="task-runner-header">
            {question.Text}
        </h3>
    )
}