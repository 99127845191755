import React from 'react';
import { MdTexture, MdChevronLeft } from 'react-icons/md';
import util from '../CommonJs/util';
import '../CommonJs/NavTransitions.scss';
import './MainLayout.scss';
import { useLockScreenState } from '../CommonJs/LockScreen';
import Tabs from './Tabs';
import { useApp } from '../lib/context';
import { useUpdateProperty } from '../CommonJs/utilTs';
import { Link } from '../CommonJs/Nav';
import { useBreakpointBodyClasses } from '../CommonJs/WindowSize';

interface MainLayoutProps
{
    links?:any;
    className?:string;
    children?:any;
}

export default function MainLayout({
    links,
    className,
    children,
    }:MainLayoutProps)
{

    const app=useApp();
    useUpdateProperty(app,'backBtnRefs');
    const {backBtnRefs}=app;

    useBreakpointBodyClasses();

    const {isLocked}=useLockScreenState();

    return (
        <div className={util.mergeClassNames("main-layout nav-transition-fade",className)}>
            <nav className="main-nav dark-bg">
                {backBtnRefs?
                    <Link back={true}><MdChevronLeft size="2em"/></Link>
                :
                    <MdTexture size="2em"/>
                }
                <ul>
                    {links}
                </ul>
            </nav>
            <div className="main-layout-content">
                {children}
            </div>
            <Tabs/>
            <div className={"main-layout-lock"+(isLocked?' locked':'')}></div>
        </div>
    )

}