import React, { useState, useCallback } from 'react';
import FormGroup, { FormGroupProps } from './FormGroup';

const wrapperStyle={
    cursor:'pointer'
}
const emptyObj={};

interface ClickToEditProps extends FormGroupProps
{
    wrapWithSpan?:boolean;
    wrapperProps?:any;
    children:any;
}

export default function ClickToEdit({children,wrapWithSpan,wrapperProps,...props}:ClickToEditProps)
{

    const [open,setOpen]=useState(false);
    const click=useCallback(()=>{
        setOpen(true);
    },[]);

    if(!open){
        if(!wrapperProps){
            wrapperProps=emptyObj;
        }
        if(wrapWithSpan){
            return <span onClick={click} style={wrapperStyle} {...wrapperProps}>{children}</span>
        }else{
            return <div onClick={click} style={wrapperStyle} {...wrapperProps}>{children}</div>
        }
    }

    return <FormGroup autoFocus={true} onBlur={()=>setOpen(false)} {...props} />
    
}