import React, { useCallback } from 'react';
import './EditorLayout.scss';
import { useApp } from '../lib/context';
import { useWindowSize } from '../CommonJs/WindowSize';

export interface EditorMenuItem
{
    /**
     * Name of the view the item matches with. Should always be all lowercase
     */
    viewName?:string;
    path?:((state:any)=>string|null)|string|null;
    content:any;
    match?:RegExp;
}

export function useViewNameForIndex(viewName:string|null,state:any|null,menuItems:EditorMenuItem[]):
    [number, (index:number)=>void]
{

    const {nav}=useApp();

    let index=0;
    const vn=viewName?viewName.toLowerCase():'';
    if(menuItems && viewName){
        for(let i=0;i<menuItems.length;i++){
            const item=menuItems[i];
            if(item.viewName===vn){
                index=i;
                break;
            }
        }
    }

    const setIndex=useCallback((index:number)=>{
        if(menuItems){
            const item=menuItems[index];
            if(item && item.path){
                const p=(typeof item.path==='function')?item.path(state):item.path;
                if(p){
                    nav.push(p);
                }
            }

        }
    },[menuItems,nav,state]);

    return [index,setIndex];
}

interface EditorLayoutProps
{
    menuItems:EditorMenuItem[];
    content:any[];
    index:number;
    setIndex:(index:number)=>void;
}

export default function EditorLayout({
    menuItems,
    content,
    index,
    setIndex
}:EditorLayoutProps){

    const {isMobile}=useWindowSize();

    return (
        <div className="editor-layout">
            {!isMobile&&
                <ul className="editor-layout-menu">
                    {menuItems.map(({content},i)=>(
                        <li onClick={()=>setIndex&&setIndex(i)} key={i} className={i===index?'active':''}>{content}</li>
                    ))}
                </ul>
            }
            <div className="editor-layout-content">
                <div style={{top:index*-100+'%'}}>
                    {content.map((content,i)=>(
                        <div key={i} style={{top:i*100+'%'}} className={'editor-layout-content-item'+(i===index?' active':'')}>
                            {content}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )

}