import React, { useContext, DependencyList, useCallback, useState, Dispatch, SetStateAction, useEffect } from 'react';
import ItpApp from "./ItpApp";
import {useEmitter, useAsync, useUpdateProperty} from '../CommonJs/utilTs';
import { SignIn } from '../CommonJs/AuthTypes';

export const ItpAppContext:React.Context<ItpApp|null>=React.createContext<ItpApp|null>(null);

export function useApp(){
    return useContext(ItpAppContext) as ItpApp;
}

export function useSignIn():SignIn|null
{
    const {auth}=useApp();
    useUpdateProperty(auth,'signIn');
    return auth.signIn;
}

export function useAppAsync<TValue,TDefault>(
    defaultValue:TDefault,
    callback:(app:ItpApp)=>Promise<TValue>,
    deps:DependencyList|null=null):TValue|TDefault
{
    if(!deps){
        deps=[];
    }
    const app=useApp();
    const cb=useCallback(callback,deps);
    return useAsync<TValue,TDefault>(defaultValue,()=>{
        return cb(app);
    },[app,cb]);
}



export function useAppMutableAsync<TValue,TDefault>(
    defaultValue:TDefault,
    callback:(app:ItpApp)=>Promise<TValue>,
    deps:DependencyList|null=null):[TValue|TDefault,Dispatch<SetStateAction<TValue|TDefault>>]
{
    if(!deps){
        deps=[];
    }
    const app=useApp();
    const cb=useCallback(callback,deps);
    const [value,setValue]=useState<TValue|TDefault>(defaultValue);
    useAsync<TValue,TDefault>(defaultValue,async ()=>{
        const v=await cb(app);
        setValue(v);
        return v;
    },[app,cb]);
    
    return [value,setValue];
}

export function useAppState()
{
    const app=useApp();
    useEmitter(app,'state');
    return app.state;
}

export function useBackButton()
{
    const app=useApp();
    useEffect(()=>{
        app.refBackButton(1);
        return ()=>{
            app.refBackButton(-1);
        }
    },[app]);
}

export function useNoTabs()
{
    const app=useApp();
    useEffect(()=>{
        app.refNoTabs(1);
        return ()=>{
            app.refNoTabs(-1);
        }
    },[app]);
}