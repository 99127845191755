import EventEmitterEx from "../CommonJs/EventEmitterEx";
import Http from "../CommonJs/Http";
import {AuthManager} from "../CommonJs/Auth";
import ItpApi from "./ItpApi";
import { AppState, createDefaultAppState } from './AppState';
import AsyncObjStore from '../CommonJs/AsyncObjStore';
import { Nav, defaultNav } from "../CommonJs/Nav";
import LockScreen from "../CommonJs/LockScreen";
import LockScreenHttp from '../CommonJs/LockScreenHttp';

export default class ItpApp extends EventEmitterEx
{
    http:Http;

    auth:AuthManager;

    api:ItpApi;

    state:AppState;

    store:AsyncObjStore;

    nav:Nav;

    lockScreen:LockScreen;

    lockScreenHttp:LockScreenHttp;

    backBtnRefs:number=0;

    noTabsRefs:number=0;

    constructor(config:any)
    {
        super();

        this.lockScreen=new LockScreen();

        this.nav=defaultNav;

        this.store=new AsyncObjStore('itp-app-v1');

        this.state=createDefaultAppState();

        this.http=new Http(config.apiBaseUrl);

        this.lockScreenHttp=new LockScreenHttp(this.lockScreen,this.http);

        this.api=new ItpApi(this);

        this.auth=new AuthManager(this.http,this.store);
    }

    async initAsync()
    {
        await this.auth.initAsync();
    }

    dispose()
    {
        this.auth.dispose();
        this.lockScreenHttp.dispose();
    }

    refBackButton(r:number)
    {
        this.backBtnRefs+=r;
        this.emitProperty(this,'backBtnRefs');
    }

    refNoTabs(r:number)
    {
        this.noTabsRefs+=r;
        this.emitProperty(this,'noTabsRefs');
    }

    hardReset()
    {
        window.localStorage.clear();
        window.location.reload(true);
    }

    
}