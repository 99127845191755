import React from 'react';
import { useAppAsync, useApp } from '../../lib/context';
import Loader from '../Loader';
import { Table } from 'react-bootstrap';
import { Link } from '../../CommonJs/Nav';
import { useWindowSize } from '../../CommonJs/WindowSize';
import {FaPlus} from 'react-icons/fa';

export default function Tasks()
{

    const {nav}=useApp();
    const tasks=useAppAsync(null,({api})=>api.getTaskSummariesAsync());
    const {stack}=useWindowSize();

    if(!tasks){
        return <Loader/>
    }

    return (
        <>
            {stack?
                <>
                    <h2>Tasks</h2>
                    <ul className="list-group mb-5">
                        {tasks.map((t,i)=>(
                            <li key={i} className="list-group-item fill-with-link">
                                <Link to={'/task/'+t.TaskId}>{t.Name}</Link>
                            </li>
                        ))}
                    </ul>
                    <Link btnType="primary" to="/task/create">Create new Task <FaPlus/></Link>
                </>
            :
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            {!stack&&<>
                                <th>Team</th>
                                <th>Org</th>
                                <th className="p-0">
                                    <Link btnType="primary" to="/task/create">+</Link>
                                </th>
                            </>}
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.map((t,i)=>{

                            const rowClick=()=>{
                                nav.push('/task/'+t.TaskId);
                            }

                            return (
                                <tr key={i}>
                                    <td onClick={rowClick}>{i+1}</td>
                                    <td onClick={rowClick}>{t.Name}</td>
                                    {!stack&&<>
                                        <td onClick={rowClick}>{t.TeamName}</td>
                                        <td onClick={rowClick}>{t.OrgName}</td>
                                        <td>...</td>
                                    </>}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            }
        </>
    )
}