import React, { useState, useCallback } from 'react';
import { Table, Button, Form } from 'react-bootstrap';
import Well from '../Well';
import Modal from '../Modal';
import FormGroup from '../FormGroup';
import {TaskTypesAry} from '../../lib/TaskTypes';
import { preventDefault } from '../../CommonJs/utilTs';
import { MdAdd, MdDelete } from 'react-icons/md';
import { useQuestions } from '../../lib/apiHooks';
import Loader from '../Loader';
import { useApp } from '../../lib/context';
import { useWindowSize } from '../../CommonJs/WindowSize';
import { Link } from '../../CommonJs/Nav';

interface TaskQuestionsProps
{
    taskId:number|null;
}

export default function TaskQuestions({
    
    taskId
}:TaskQuestionsProps)
{

    const {nav}=useApp();
    const [questions,qCtl]=useQuestions(taskId);
    const [addOpen,setAddOpen]=useState(false);
    const {stack}=useWindowSize();


    const {isMobile}=useWindowSize();

    const taskAddComplete=useCallback(()=>{
        setAddOpen(false);
    },[]);

    if(!questions){
        return <Loader/>
    }

    return (
        <>
            <h3 className="mt-5">Questions</h3>
            {questions.length!==0&&
                (stack?
                    <>
                        <ul className="list-group mb-5">
                            {questions.map((q,i)=>(
                                    <li key={i} className="list-group-item fill-with-link">
                                        <Link to={`/task/${taskId}/questions/${q.Id}`}>{q.Text||'New Question'}</Link>
                                    </li>
                                ))}
                        </ul>
                        <Button variant="primary" className="w-100" onClick={()=>setAddOpen(true)}>Add new Question</Button>
                    </>
                :
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Type</th>
                                {isMobile?null:<th>Question</th>}
                                <th style={{width:'1px'}}><Button variant="primary" onClick={()=>setAddOpen(true)}><MdAdd/></Button></th>
                            </tr>
                        </thead>
                        <tbody>
                            {questions.map((q,i)=>{
                                
                                const open=()=>{
                                    nav.push(`/task/${taskId}/questions/${q.Id}`);
                                };
                                
                                return (
                                    <tr key={i}>
                                        <td onClick={open}>{i+1}</td>
                                        <td onClick={open}>{q.Type}</td>
                                        {isMobile?null:<td onClick={open}>{q.Text}</td>}
                                        <td>
                                            <Button variant="danger" onClick={()=>qCtl.removeItem(q.Id)}>
                                                <MdDelete/>
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                )
            }

            {questions.length===0&&
                <Well>
                    <div>
                        This task has no Questions so far.
                    </div>
                    <Button variant="primary" onClick={()=>setAddOpen(true)}>Add Question</Button>
                </Well>
            }

            <Modal isOpen={addOpen} closeRequested={setAddOpen}>
                <AddQuestion complete={taskAddComplete} add={qCtl.addItemAsync}  />
            </Modal>
        </>
    )
}

interface AddQuestionProps
{
    add:(type:string)=>Promise<void>;
    complete:()=>void;
}

function AddQuestion({complete,add}:AddQuestionProps)
{
    const [type,setType]=useState(0);

    const submit=useCallback(async (e)=>{
        preventDefault(e);

        await add(TaskTypesAry[type]);

        complete();

    },[complete,type,add]);

    return (
        <Form onSubmit={submit}>
            <h3>Add New Question</h3>
            <FormGroup label="Type" value={type} onOptionChange={setType} optionLabel="" options={TaskTypesAry} />
            <Button className="w-100" type="submit" variant="primary">Add</Button>
        </Form>
    )
}