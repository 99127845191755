import React, { useState } from 'react';
import Loader from '../Loader';
import { useApp, useAppAsync } from '../../lib/context';
import { Form, Button } from 'react-bootstrap';
import FormGroup from '../FormGroup';
import { useLockScreen } from '../../CommonJs/LockScreen'
import Log from '../../CommonJs/Log';
import { MdAccountCircle } from 'react-icons/md';
import ScrollView from '../../CommonJs/ScrollView';
import { FaCog } from 'react-icons/fa';
import { Link } from '../../CommonJs/Nav';


export default function Profile()
{

    const {api,auth}=useApp();
    const lockScreen=useLockScreen();

    const [firstName,setFirstName]=useState<string>('');
    const [lastName,setLastName]=useState<string>('');
    const [email,setEmail]=useState<string>('');


    const user=useAppAsync(null,async ({api})=>{
        const user=await api.getUserSelfAsync();
        if(user){
            setFirstName(user.FirstName||'');
            setLastName(user.LastName||'');
            setEmail(user.Email||'');
        }
        return user;
    });

    var signOutBtn=<Button variant="primary" type="button" onClick={()=>auth.signOutAsync()}>Sign Out</Button>

    if(!user){
        return <>
            <Loader />
            <div>
                {signOutBtn}
            </div>
        </>
    }

    const submit=async (e)=>{
        e.preventDefault();

        if(!user){
            return;
        }

        lockScreen.lock('Updating Profile');

        try{
            await api.updateUserProfileAsync({
                UUID:user.UUID,
                UpdateUnsetOnly:false,
                FirstName:firstName,
                LastName:lastName,
                Email:email
            })
        }catch(ex){
            Log.error('Unable to update user profile',ex);
        }finally{
            lockScreen.unlock();
        }

    }

    return (
        <div className="flex-fill">
            <ScrollView>
                <div className="container">
                    <Form onSubmit={submit} className="flex flex-column mb-5">

                        <div className="d-flex flex-row justify-content-center">
                            <MdAccountCircle size="5rem"/>
                        </div>

                        <FormGroup type="email" label="Email" value={email} onChange={setEmail} />
                        <FormGroup type="text" label="First Name" value={firstName} onChange={setFirstName} />
                        <FormGroup type="text" label="Last Name" value={lastName} onChange={setLastName} />
                        <Button className="mr-2" variant="primary" type="submit">Update</Button>
                        {signOutBtn}
                    </Form>
                </div>
            </ScrollView>

            <div style={{position:'absolute',right:0,top:0}}>
                <Link to="/settings">
                    <FaCog color="#ddd" size={30} style={{marginRight:'15px',marginTop:'15px'}}/>
                </Link>
            </div>
        </div>
    )
}