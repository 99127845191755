import React from 'react';
import Loader from '../Loader';
import { Row, Col } from 'react-bootstrap';
import { PublicTaskSummary } from '../../lib/paramTypes';
import { useApiGet } from '../../lib/apiHooks';
import { formatMoney } from '../../lib/ItpUtil';
import { Link } from '../../CommonJs/Nav';

interface TaskSummaryViewProps
{
    taskId:number|null;
}

export default function TaskSummaryView({
    taskId,
}:TaskSummaryViewProps){


    const task=useApiGet<PublicTaskSummary>(
        'Loading Survey',
        taskId?`Respondent/Task/${taskId}/Summary`:null,
        null,
        [taskId]);

    if(!task){
        return <Loader/>
    }
    
    let time=Math.ceil(task.EstimatedTime/60);

    if(time<1){
        time=1;
    }

    const maxTime=Math.ceil(time*1.6);

    return (
        <>
            
            <h2 className="mb-4">{task.Name}</h2>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h4 className="m-0">{task.Description}</h4>
                <h3 className="text-success m0">{formatMoney(task.Payout)}</h3>
            </div>
            
            <p className="mb-4">
                This survey takes {time} minute{time>1?'s':''} to complete. <b>Please give your
                self {maxTime} minute{maxTime>1?'s':''}</b> complete the survey before beginning 
            </p>

            <Row>
                <Col>
                    <Link className="w-100 btn btn-primary" to={`/survey/${taskId}`}>Start Survey</Link>
                </Col>
            </Row>
        </>
    )
}