import React, { useCallback, useState } from 'react';
import { useApp } from '../lib/context';
import { SignInOrError, SignInTypes } from '../CommonJs/AuthTypes';
import { useMounted, useProperty } from '../CommonJs/utilTs';
import './SignIn.scss';
import ScrollView from '../CommonJs/ScrollView';

export default function SignIn(props:any)
{

    const {auth}=useApp();
    const signIn=useProperty(auth,'signIn',auth=>auth.signIn);
    const [register,setRegister]=useState(false);
    const [email,setEmail]=useState('');
    const [firstName,setFirstName]=useState('');
    const [lastName,setLastName]=useState('');
    const [password,setPassword]=useState('');
    const [confirmPassword,setConfirmPassword]=useState('');
    const [result,setResult]=useState<SignInOrError>({signIn:null,error:null});
    const mounted=useMounted();

    const signInSubmit=useCallback(async(e)=>{
        if(e){
            e.preventDefault();
        }

        if(!password){
            setResult({error:'Password Required'});
            return;
        }

        let result:SignInOrError;
        if(!register){
            result=await auth.signInAsync({
                Type:SignInTypes.EmailPassword,
                Identity:email,
                Token:password
            });
        }else{
            if(password!==confirmPassword){
                setResult({error:'Passwords do not match'});
                return;
            }
            result=await auth.registerAsync({
                Type:SignInTypes.EmailPassword,
                Email:email,
                Password:password,
                FirstName:firstName,
                LastName:lastName
            });
        }
        if(mounted.mounted){
            setResult(result);
        }
    },[
        auth,mounted,
        email,
        firstName,lastName,
        password,confirmPassword,
        register
    ]);

    const signOut=useCallback((e)=>{
        if(e){
            e.preventDefault();
        }
    },[]);

    return (
        <div className="d-flex flex-fill">
            <ScrollView>
            <div className="card sign-in-card bg-light">
                <img className="card-img-top" src="/images/Genz_768w.jpg" alt="Card cap"/>
                {signIn?
                    <div className="card-body">
                        <h5 className="card-title">Welcome</h5>
                        <p className="card-text">{signIn.FirstName} {signIn.LastName}</p>
                        <button className="btn btn-primary" onClick={signOut}>
                            Sign out
                        </button>
                    </div>
                    :
                    <form onSubmit={signInSubmit}>
                        <div className="card-body">
                            <h5 className="card-title">Sign In</h5>
                            {result.error&&<div className="alert alert-danger">{result.error}</div>}
                            <p className="card-text">Sign to get started.</p>
                            <div className="form-row">
                                <div className="col">
                                    <input
                                        value={email} onChange={e=>setEmail(e.target.value)}
                                        required type="email" className="form-control" placeholder="Email"/>
                                </div>
                            </div>
                            <div className="form-row mt-4">
                                <div className="col">
                                    <input
                                        value={password} onChange={e=>setPassword(e.target.value)}
                                        required type="password" className="form-control" placeholder="Password"/>
                                </div>
                            </div>
                            {register&&<>
                                <div className="form-row mt-4">
                                    <div className="col">
                                        <input
                                            value={confirmPassword} onChange={e=>setConfirmPassword(e.target.value)} 
                                            required type="password" className="form-control" placeholder="Confirm Password"/>
                                    </div>
                                </div>
                                <div className="form-row mt-4">
                                    <div className="col">
                                        <input
                                            value={firstName} onChange={e=>setFirstName(e.target.value)} 
                                            required className="form-control" placeholder="First Name"/>
                                    </div>
                                </div>
                                <div className="form-row mt-4">
                                    <div className="col">
                                        <input
                                            value={lastName} onChange={e=>setLastName(e.target.value)} 
                                            required className="form-control" placeholder="Last Name"/>
                                    </div>
                                </div>
                            </>}
                            <div className="form-row mt-4">
                                <div className="col-12">
                                    <button className="btn btn-primary w-100">
                                        {register?'Register':'Sign In'}
                                    </button>
                                </div>
                            </div>
                            <div className="form-row mt-4">
                                
                                {register?
                                    <div className="col-auto mt-3">
                                        <button className="btn btn-info" type="button" onClick={()=>setRegister(false)}>I already have an account</button>
                                    </div>
                                :
                                    <div className="col-auto mt-3 flex-column d-flex">
                                        <span className="text-muted mb-2">I need an account.</span>
                                        <button className="btn btn-info" type="button" onClick={()=>setRegister(true)}>Register</button>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </form>
                }
            </div>

            </ScrollView>
        </div>
    )
}