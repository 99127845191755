import React, { useState, useCallback } from 'react';
import { useAppAsync, useApp } from '../../lib/context';
import Loader from '../Loader';
import { Button } from 'react-bootstrap';
import { useMounted } from '../../CommonJs/utilTs';
import Form from 'react-bootstrap/Form';
import FormGroup from '../FormGroup';
import { TeamRole } from '../../lib/types';

export default function TaskCreate()
{
    const mounted=useMounted();
    const {api,nav}=useApp();

    const teams=useAppAsync(null,async ({api})=>api.getUserTeamsAsync(TeamRole.Manage));
    const [teamIndex,setTeamIndex]=useState(0);

    const [name,setName]=useState('');

    const submit=useCallback(async (e)=>{
        if(e){
            e.preventDefault();
        }

        const team=teams&&teams[teamIndex];

        if(!team || !name){
            return;
        }

        const task=await api.createTaskAsync({
            Name:name,
            TeamId:team.Id
        });

        if(!mounted.mounted || !task){
            return;
        }

        nav.push('/task/'+task.Id)


    },[name,teams,teamIndex,mounted,api,nav]);

    if(!teams){
        return <Loader/>
    }

    return (
        <Form onSubmit={submit}>
            <h2>Cerate new Task</h2>
            <FormGroup label="Name" value={name} onChange={setName} />
            
            <FormGroup label="Team"
                options={teams} optionLabel="Name"
                value={teamIndex} onOptionChange={setTeamIndex} />

            <Button variant="primary" type="submit" className="w-100 mt-4">Create</Button>
        </Form>
    )
}