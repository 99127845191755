import React from 'react';
import { useApiGet } from '../../lib/apiHooks';
import { PublicTaskSummary } from '../../lib/paramTypes';
import { ListGroup } from 'react-bootstrap';
import Loader from '../Loader';
import accounting from 'accounting';
import IconLink from '../IconLink';
import { MdPlayCircleFilled } from 'react-icons/md';

export default function RespondentTaskList()
{

    const tasks=useApiGet<PublicTaskSummary[]>(
        'Loading Surveys',
        `Respondent/ActiveTasks`,
        null,
        []);

    if(!tasks){
        return <Loader/>
    }

    return (
        <ListGroup>
            {tasks.map(t=>(
                <ListGroup.Item key={t.TaskId}>
                    <h2>{t.Name}</h2>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <p className="m-0">{t.Description}</p>
                        <h3 className="text-success">{accounting.formatMoney(t.Payout)}</h3>
                    </div>
                    <IconLink to={`/survey-summary/${t.TaskId}`} className="w-100">
                        Start Survey <MdPlayCircleFilled size={28}/>
                    </IconLink>
                </ListGroup.Item>
            ))}
        </ListGroup>
    )
}