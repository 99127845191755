import React, { useMemo } from 'react';
import Loader from '../Loader';
import { Link } from '../../CommonJs/Nav';
import SlidePanel from '../../CommonJs/SlidePanel';
import ScrollView from '../../CommonJs/ScrollView';
import EditorLayout, { EditorMenuItem, useViewNameForIndex } from '../EditorLayout';
import Well from '../Well';
import { MdLayers } from 'react-icons/md';
import { iconSize } from '../../style/style';
import TaskQuestions from './TaskQuestions';
import TaskQuestion from './TaskQuestion';
import ClickToEdit from '../ClickToEdit';
import { useTask, useTaskSummary, useQuestions } from '../../lib/apiHooks';
import FormGroup from '../FormGroup';
import accounting from 'accounting';
import { ItpTaskStatus } from '../../lib/types';
import { useBackButton } from '../../lib/context';
import TaskResults from './TaskResults';
import TaskResult from './TaskResult';

const questionsIndex=1;
//const mediaIndex=2;
const resultsIndex=3;

interface TaskProps
{
    taskId:number|null;
    viewName:string|null;
    subId:number|null;
}

const menuItems:EditorMenuItem[]=[
    {
        viewName:'',
        path:(state:TaskProps)=> `/task/${state.taskId}`,
        content:'Properties'
    },
    {
        viewName:'questions',
        path:(state:TaskProps)=> `/task/${state.taskId}/questions`,
        content:'Questions'
    },
    {
        viewName:'media',
        path:(state:TaskProps)=> `/task/${state.taskId}/media`,
        content:'Media'
    },
    {
        viewName:'results',
        path:(state:TaskProps)=> `/task/${state.taskId}/results`,
        content:'Results',
    },
];

export default function Task({
    taskId,
    viewName,
    subId
}:TaskProps){

    const [task,saveTask]=useTask(taskId,true);
    const summary=useTaskSummary(taskId);
    const [questions]=useQuestions(taskId);

    const menuState=useMemo<TaskProps>(()=>({taskId,viewName,subId}),[taskId,viewName,subId]);
    const [index,setIndex]=useViewNameForIndex(viewName,menuState,menuItems);

    useBackButton();

    if(!task || !summary || !questions){
        return <Loader/>
    }

    const questionId=(index===questionsIndex) && subId!==null?subId:null;
    const resultId=(index===resultsIndex) && subId!==null?subId:null;

    return (
        <EditorLayout
            index={index} setIndex={setIndex}
            menuItems={menuItems}
            content={[

                // Properties
                <div className="editor-layout-container">
                    <ClickToEdit label="Name" showLabel={false} value={task.Name} onChange={n=>{
                        task.Name=n;
                        saveTask();
                    }}>
                        <h2>{task.Name}</h2>
                    </ClickToEdit>

                    <FormGroup label="Status" optionsEnum={ItpTaskStatus} value={task.Status} onOptionChange={v=>{
                        task.Status=v;
                        saveTask();
                    }} />

                    <FormGroup label="Description" value={task.Description} onChange={v=>{
                        task.Description=v;
                        saveTask();
                    }} />

                    <FormGroup label="Payout" value={accounting.formatMoney(task.Payout)} onChange={v=>{
                        
                        task.Payout=accounting.unformat(v);
                        saveTask();
                    }} />

                    <h4 className="mt-5">Questions</h4>
                    <Link to={`/task/${taskId}/questions`}>
                        <Well>
                            <div>
                                {questions.length?questions.length+' Questions(s)':'No Questions Yet'}
                            </div>
                            <MdLayers size={iconSize}/>
                        </Well>
                    </Link>

                    <h4 className="mt-5">Results</h4>
                    <Link to={`/task/${taskId}/results`}>
                        <Well>
                            <div>
                                {summary.ResultCount?summary.ResultCount+' Result(s)':'No Results Yet'}
                            </div>
                            <MdLayers size={iconSize}/>
                        </Well>
                    </Link>

                    
                </div>
                
                ,// Questions
                <div className="editor-layout-container">
                    <SlidePanel index={(questionId==null)?0:1}>
                        <ScrollView>
                            <TaskQuestions taskId={taskId} />
                        </ScrollView>
                        <ScrollView>
                            <TaskQuestion questionId={questionId} />
                        </ScrollView>
                    </SlidePanel>
                </div>


                ,// Media
                <div className="editor-layout-container">Media</div>

                ,// Results
                <div className="editor-layout-container">
                    <SlidePanel index={(resultId==null)?0:1}>
                        <ScrollView>
                            <TaskResults taskId={taskId} />
                        </ScrollView>
                        <ScrollView>
                            <TaskResult taskId={taskId} resultId={resultId} />
                        </ScrollView>
                    </SlidePanel>
                    
                </div>
            ]}
        />
    )
}