const hostname=window.location.hostname;
const isLocal=hostname.indexOf('localhost')===0;
const isTunnel=hostname.endsWith('.ngrok.io');
const apiHost=isLocal?hostname+':44345':(isTunnel?'itp-api-dev.ngrok.io':'api.itp.opencanvas.services');
const s=isLocal?'s':'s';

export default {
    apiHost:apiHost,
    apiBaseUrl:`http${s}://${apiHost}/api/`,
    apiBaseWsUrl:`ws${s}://${apiHost}/api/`
}